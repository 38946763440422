import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';

import { Wrapper } from 'components/common/wrapper/Wrapper';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import ReviewCentreGroup from 'components/directus/review-centre/review-centre-group-review/ReviewCentreGroupReview';
import customerReviewsHook from 'hooks/customer-reviews/customer-reviews.hook';
import { checkFloat } from 'utils/check-float';
import { sort } from 'utils/directus';
import { Props } from './ReviewCentreGroup.interface';

import {
  banner,
  banner__title,
  banner__sub,
  banner__sub__score,
  banner__sub__stars,
  banner__sub__totalReviews,
  banner__sub__reviewsLink,
  reviews as reviewsClass,
  reviews__logoContainer,
  reviews__group,
  reviews__group__block,
  reviews__group__link,
} from './ReviewCentreGroup.scss';

export default ({ slug, reviews }: Props): JSX.Element => {
  const stats = customerReviewsHook();
  const stat = stats.find(({ node }) => node.slug === slug);
  const group = stat.node;

  return (
    <>
      <div className={banner}>
        <Wrapper>
          <h2 className={banner__title}>Our {group.provider} reviews</h2>

          <div className={banner__sub}>
            <span className={banner__sub__score}>{checkFloat(group.score) ? `${group.score}.0` : group.score}</span>
            <RatingStars
              score={group.score}
              numberOfStars={5}
              starColor="#fff"
              starDimension="30px"
              className={banner__sub__stars}
            />
            <span className={banner__sub__totalReviews}>
              {parseInt(group.total_reviews as string, 10).toLocaleString()} reviews
            </span>
            <a href={group.link} className={banner__sub__reviewsLink} target="_blank" rel="noreferrer">
              Read All Reviews&nbsp;
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
        </Wrapper>
      </div>

      <div className={reviewsClass}>
        <Wrapper>
          <div className={reviews__logoContainer}>
            <img src={stat.node.logo.data.full_url} alt="Review company logo" />
          </div>
          <p>Our last five reviews....</p>

          <div className={reviews__group}>
            {sort(
              reviews.map((review) => (
                <div className={reviews__group__block} key={`review-centre-group-review-${review.directusId}`}>
                  <ReviewCentreGroup
                    imageUrl={review.userImageUrl}
                    imageAlt={review.userImageAlt}
                    userFullName={review.userName}
                    starScore={Number(review.score)}
                    dateOfCreation={review.dateOfCreation}
                    body={review.body}
                    review_date={review.review_date}
                  />
                </div>
              ))
            )}

            <div className={reviews__group__block}>
              <a href={group.link} className={reviews__group__link} target="_blank" rel="noreferrer">
                Read all reviews <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};
